<template>
  <div class="dialog-privacy">
    <div class="wrapper">
      <div class="dialog-privacy__content">
        <strong>提示</strong>
        <p>请先阅读并同意 <router-link to="/agreement/privacy">《隐私协议》</router-link></p>
        <div class="actions">
          <div class="actions__item cancel" @click="handleCancel">取消</div>
          <span class="actions__separator"></span>
          <div class="actions__item done" @click="handleDone">同意</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DialogPrivacy',
  props: {
    msg: String
  },
  methods: {
    handleCancel() {
      this.$emit("cancel")
    },
    handleDone() {
      this.$emit("done")
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/dialog_privacy.scss';
</style>
