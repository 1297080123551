<template>
    <div class="bazijp-index wrapper">
        <div class="head-marquee" v-if="webInfo.marquee_text">
            <div class="marquee-text">{{ webInfo.marquee_text }}</div>
        </div>
        <div class="header">
            <img src="@/assets/bazijp/images/header.png" width="100%">
            <div class="pan">
                <div class="bottom">
                    <img src="@/assets/bazijp/images/pan1.png">
                </div>
                <div class="middle">
                    <img src="@/assets/bazijp/images/pan2.png">
                </div>
                <div class="top">
                    <img src="@/assets/bazijp/images/pan3.png">
                </div>
            </div>
        </div>
        <div class="commit">
            <input type="image" class="btn-cesuan" :src="require('@/assets/bazijp/images/btn_cesuan.png')"
                @click="toInfo">
        </div>
        <div class="section">
            <img src="@/assets/bazijp/images/content1.png" width="100%">
        </div>
        <div class="section">
            <img src="@/assets/bazijp/images/content2.png" width="100%">
        </div>
        <div class="section">
            <img src="@/assets/bazijp/images/content3.png" width="100%">
        </div>
        <div class="section">
            <img src="@/assets/bazijp/images/content4.png" width="100%">
        </div>
        <div class="copy" v-if="webInfo && webInfo.company_name">
            -- 版权信息 --
            <br>{{ webInfo.company_name }}
            <br>{{ webInfo.foot_text }}
            <p v-if="webInfo.tel">电话：{{ webInfo.tel }}</p>
            <p v-if="webInfo.address">地址：{{ webInfo.address }}</p>
        </div>
        <div class="footer-float" v-if="shouldShowBackToTop">
            <div class="wrapper">
                <input type="image" class="btn-cesuan" :src="require('@/assets/bazijp/images/btn_cesuan.png')"
                    @click="toInfo">
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'BzjpIndex',
    computed: {
        ...mapState(['webInfo'])
    },
    data() {
        return {
            marqueeText: process.env.VUE_APP_MARQUEE_TEXT,
            shouldShowBackToTop: false
        }
    },
    mounted() {
        document.title = '生辰八字精批';
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        toInfo() {
            this.$router.push('/bazijp/info')
        },
        handleScroll() {
            const commit = this.$el.querySelector('.commit');
            if (commit) {
                const commitRect = commit.getBoundingClientRect();
                this.shouldShowBackToTop = commitRect.top + commitRect.height < 0;
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/bazijp/sass/index.scss';
</style>