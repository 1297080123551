<template>
    <form class="bazijp-result wrapper" method="get" @submit.prevent="handleSubmit">
        <div class="header">
            <div class="top-half">
                <img :src="require('@/assets/images/' + masterImage)" class="avatar">
                <div class="info">
                    <div class="info-heading">
                        <strong>{{ order.data.username }}缘主</strong>
                        <input type="submit" value="联系老师">
                    </div>
                    <p>
                        您的出生年干支为{{ order.data_extend.pp.ygz }}，生于{{ order.data_extend.return.user.siji }}季，本命属{{
        data.data_extend.return.user.sx }}，{{
        data.data_extend.pp.nayin1
    }}命。属{{ order.data_extend.return.info.wharr.wang }}，命理{{ order.data_extend.return.info.wharr.que
                        }}。</p>
                </div>
            </div>
            <div class="tips">以下是您的在线测算结果，请滑动屏幕查看的您的专属报告</div>
            <div class="your-info">您的个人资料</div>
            <div class="user-info">
                <div class="basic-info">
                    <div class="info-item">
                        <label>姓名:</label>
                        <div class="name col2">
                            <span class="word" v-for="(word, index) of data.data.username" :key="index">{{ word
                                }}</span>
                        </div>
                    </div>
                    <div class="info-item">
                        <label>性别:</label>
                        <div class="sex col">{{ data.data.gender | gender }}</div>
                        <label>属相:</label>
                        <div class="sx col">{{ order.data_extend.return.user.sx }}</div>
                    </div>
                    <div class="info-item">
                        <label>生辰:</label>
                        <div class="sex col2">{{ order.data.lDate }}</div>
                    </div>
                    <small>五行旺弱：您的五行失衡，磁场较弱</small>
                </div>
                <div class="year-sx">
                    <img src="@/assets/bazijp/images/result_sx_box.png" class="sx-pic">
                    <div class="year">2024生肖运势</div>
                    <input type="image" :src="require('@/assets/bazijp/images/result_btn_hjts.png')" class="btn">
                </div>
                <img src="@/assets/bazijp/images/result_combo.png" width="100%">
                <table class="bazi">
                    <tr>
                        <th>八字</th>
                        <th>年柱</th>
                        <th>月柱</th>
                        <th>日柱</th>
                        <th>时柱</th>
                    </tr>
                    <tr>
                        <td>天干</td>
                        <td>{{ order.data_extend.return.user.bazi[0] }}</td>
                        <td>{{ order.data_extend.return.user.bazi[2] }}</td>
                        <td>{{ order.data_extend.return.user.bazi[4] }}</td>
                        <td>{{ order.data_extend.return.user.bazi[6] }}</td>
                    </tr>
                    <tr>
                        <td>地支</td>
                        <td>{{ order.data_extend.return.user.bazi[1] }}</td>
                        <td>{{ order.data_extend.return.user.bazi[3] }}</td>
                        <td>{{ order.data_extend.return.user.bazi[5] }}</td>
                        <td>{{ order.data_extend.return.user.bazi[7] }}</td>
                    </tr>
                </table>
            </div>
            <div class="ming-wrap">
                <div class="ming">
                    本命属{{ order.data_extend.return.user.sx }}，{{
        data.data_extend.pp.nayin1
    }}命。纳音命宫为{{ order.data_extend.pp.nayintaiyuan }}，生于{{ order.data_extend.return.user.siji }}季。
                </div>
            </div>
            <div class="report-heading">
                以下是您的分析报告
            </div>
        </div>
        <div class="section">
            <div class="heading">
                <h3>生辰八字解读</h3>
            </div>
            <div class="content">
                <div class="title"><strong>八字排盘，揭示你的命运之谜</strong></div>
                <table class="pan">
                    <tr>
                        <th>八字</th>
                        <th>年柱</th>
                        <th>月柱</th>
                        <th>日柱</th>
                        <th>时柱</th>
                    </tr>
                    <tr>
                        <th>天干</th>
                        <td v-html="getBaziHtml(data.data_extend.return.user.bazi[0], data.data_extend.pp.shishen1)">
                        </td>
                        <td v-html="getBaziHtml(data.data_extend.return.user.bazi[2], data.data_extend.pp.shishen2)">
                        </td>
                        <td v-html="getBaziHtml(data.data_extend.return.user.bazi[4], '[日主]')"></td>
                        <td v-html="getBaziHtml(data.data_extend.return.user.bazi[6], data.data_extend.pp.shishen4)">
                        </td>
                    </tr>
                    <tr>
                        <th>地支</th>
                        <td v-html="getBaziHtml(data.data_extend.return.user.bazi[1], data.data_extend.pp.z_shishen1)">
                        </td>
                        <td v-html="getBaziHtml(data.data_extend.return.user.bazi[3], data.data_extend.pp.z_shishen2)">
                        </td>
                        <td v-html="getBaziHtml(data.data_extend.return.user.bazi[5], data.data_extend.pp.z_shishen3)">
                        </td>
                        <td v-html="getBaziHtml(data.data_extend.return.user.bazi[7], data.data_extend.pp.z_shishen4)">
                        </td>
                    </tr>
                    <tr>
                        <th>藏干</th>
                        <td>
                            {{ order.data_extend.pp.zanggan1 }}
                        </td>
                        <td>
                            {{ order.data_extend.pp.zanggan2 }}
                        </td>
                        <td>
                            {{ order.data_extend.pp.zanggan3 }}
                        </td>
                        <td>
                            {{ order.data_extend.pp.zanggan4 }}
                        </td>
                    </tr>
                    <tr>
                        <th>命宫</th>
                        <td colspan="4">
                            {{ order.data_extend.pp.minggong }}
                        </td>
                    </tr>
                    <tr>
                        <th>胎元</th>
                        <td colspan="4">
                            {{ order.data_extend.pp.taiyuan }}
                        </td>
                    </tr>
                    <tr>
                        <th>胎息</th>
                        <td colspan="4">
                            {{ order.data_extend.return.user.bazi[6] }}
                            {{ order.data_extend.return.user.bazi[7] }}
                        </td>
                    </tr>
                </table>
                <div class="desc">
                    提示: 八字命盘从阴阳干支三合历取得。上排是天干；由五行「金水木火土」轮流排列。 下排是地支；用十二生肖顺序排列。十二生肖可转换为五行。
                </div>
                <div class="title"><strong>五行综述</strong></div>
                <table class="pan">
                    <tr>
                        <th>八字</th>
                        <th>{{ order.data_extend.return.user.bazi[0] }}{{ order.data_extend.return.user.bazi[1] }}</th>
                        <th>{{ order.data_extend.return.user.bazi[2] }}{{ order.data_extend.return.user.bazi[3] }}</th>
                        <th>{{ order.data_extend.return.user.bazi[4] }}{{ order.data_extend.return.user.bazi[5] }}</th>
                        <th>{{ order.data_extend.return.user.bazi[6] }}{{ order.data_extend.return.user.bazi[7] }}</th>
                    </tr>
                    <tr>
                        <th>五行</th>
                        <td>{{ order.data_extend.cookies.wh[0] }}{{ order.data_extend.cookies.wh[1] }}</td>
                        <td>{{ order.data_extend.cookies.wh[2] }}{{ order.data_extend.cookies.wh[3] }}</td>
                        <td>{{ order.data_extend.cookies.wh[4] }}{{ order.data_extend.cookies.wh[5] }}</td>
                        <td>{{ order.data_extend.cookies.wh[6] }}{{ order.data_extend.cookies.wh[7] }}</td>
                    </tr>
                </table>
                <div class="master-tips">
                    <div class="image">
                        <img :src="require('@/assets/images/' + masterImage)">
                        <small>大师提示</small>
                    </div>
                    宇宙万物皆有五行磁场与属性，我们的生辰就是降生时那一刻的五行状态，五行与自身命格运势息息相关，命格五行失衡，当走到五行受伤的年份，运势就会大幅度缩水，下滑，出现求财困难，求而不得，频繁破财损财，事业受阻，当进不进，犯小人劳而无功，自己的功劳被小人抢走，贵人难遇，关键时候无贵人帮助错失机遇，事业容易变动，奔波，且容易遇到投资失败，心力交瘁的情况，感情也容易守不住出现危机。了解自身五行旺缺与五行生克，知道五行旺缺对自己运势的影响，即可掌握先天运势，可借助后天五行调补先天五行的平衡，趋吉避凶，实现财运事业，健康感情等各方面的称心顺意。五行每个元素对人生都有特定意义，不管缺少哪一个都会产生相应的影响，代表财的五行缺失则与财缘薄，不善理财守财，代表配偶的五行缺失，则感情不顺，难遇良人...且在走到不利流年，则容易引动运势低估，导致霉运连连，如果命格五行有缺，可尽早借助后天风水进行调理补旺
                    <br><br>
                    命格具体五行旺衰缺失情况可以咨询老师一对一为你解答，会更准确
                </div>
                <p>
                    以前2023年的时候，有位女士来找我，我问她:你怎么了?她说家里大儿子年近35还是一直不想结婚。拿过她公子的八字一看，我说:你这孩子责任心很强，从小就比较成熟，各方面都比较不错但是就是感情上没有进展?她反问我怎么知道。她继续问道:老师，我年纪也大了，现在就是担心孩子的婚事，实在是太操心了我到底该怎么办呢。
                    <br><br>
                    我说其实并不难，你调整一下。我看了一下，这位孩子先天水比较旺，五行缺火，刚好火是他的配偶星啊，而孩子工作在北方，北方属水，是忌神方位。而且她孩子住哪呢，又是家中西北的房间。听到这里啊，我就明白为什么她的孩子迟迟都没有结婚的念头了
                    <br><br>
                    这里面是有讲究的，长子住西北叫雷天大壮，这个位置的风水就是孩子早熟懂事，责任心强，事业也不错，但是就是无心婚姻。这们女士特别心急地问我:那我该怎么办呀，怎么调?我让她呢用火来调理一下，因为我克者为财，男命以财为配偶星，所以水以火为财。简单来说就是手上佩戴上五行手链，再多去买一些红色的衣服，袜子内裤子也要买一些红色的。给孩子穿，另外也要从西北方搬出去，这位女士也很听话，回去就给孩子安排上了。还发过来张照片给我，他的整个房间都换成了红色系。
                    <br><br>
                    大概过了一个月左右，她的孩子就因为一次公司聚餐遇到一个比较心仪的人，对于婚姻也不再说不想结婚了，开始有了转变，其实啊，所有影响人的就是五行力量的生克之患，在你不顺的时候，稍微调整一下你的五行就好了，也没那么复杂。如果你实在不懂，就来找老师，告诉你五行平衡调理、补旺的方法。
                </p>
            </div>
        </div>
        <input type="image" :src="require('@/assets/bazijp/images/result_master_jiedu.png')" class="master-jiedu">
        <div class="section">
            <div class="heading">
                <h3>性格分析</h3>
            </div>
            <div class="content">
                <div class="numbers" v-html="getXgfxHtml(data.data_extend.xgfx)"></div>
                <div class="jibing">
                    {{ order.data_extend.jkfx }}
                </div>
                <p>
                    每个人的性格70%是来自命格五行以及格局，但是不代表全部，性格也会根据后天风水以及生长环境和经历塑造影响，生辰不是唯一因素 理性看待
                </p>
            </div>
        </div>
        <input type="image" :src="require('@/assets/bazijp/images/result_master_jiedu.png')" class="master-jiedu">
        <div class="section" v-if="data.data_extend.ssfx_y && data.data_extend.ssfx_m && data.data_extend.ssfx_s">
            <div class="heading">
                <h3>四柱分析</h3>
            </div>
            <div class="content four-pillars">
                <img src="@/assets/bazijp/images/four_pillars_head.png">
                <ul>
                    <li>
                        <img src="@/assets/bazijp/images/four_pillars_y.png">
                        <p><strong>{{ order.data_extend.pp.shishen1 }}分析【年柱】</strong>{{ order.data_extend.ssfx_y }}</p>
                    </li>
                    <li>
                        <img src="@/assets/bazijp/images/four_pillars_m.png">
                        <p><strong>{{ order.data_extend.pp.shishen2 }}分析【月柱】</strong>{{ order.data_extend.ssfx_m }}</p>
                    </li>
                    <li>
                        <img src="@/assets/bazijp/images/four_pillars_h.png">
                        <p><strong>{{ order.data_extend.pp.shishen4 }}分析【时柱】</strong>{{ order.data_extend.ssfx_s }}</p>
                    </li>
                </ul>
            </div>
            <input type="image" :src="require('@/assets/bazijp/images/result_master_jiedu_03.png')" class="master-jiedu">
        </div>
        <div class="section">
            <div class="heading">
                <h3>爱情分析</h3>
            </div>
            <div class="content">
                <p>
                    生辰预测中看待婚姻，男命财星为配偶星，女命官杀为配偶星，命格婚姻好坏，顺遂与否，主要看财官以及婚姻宫磁场好坏，婚姻宫好则感情顺利美满，婚姻宫不好则感情不顺，以下是您感情运势分析，如无法解答你的困惑，可以找老师详细分析
                </p>
                <strong v-if="data.data_extend.return.data.zonghe.th">您命中有: 红艳桃花 {{
        data.data_extend.return.data.zonghe.th }} 朵</strong>
                <strong v-else>您命中有: 红艳桃花 1 朵</strong>
                <p>
                    {{ order.data_extend.rglm.aqfx }}
                </p>
            </div>
        </div>
        <input type="image" :src="require('@/assets/bazijp/images/result_master_jiedu_01.png')" class="master-jiedu">
        <div class="section">
            <div class="heading">
                <h3>事业财富</h3>
            </div>
            <div class="content">
                <div class="title"><strong>事业分析</strong></div>
                <p>
                    {{ order.data_extend.rglm.syfx }}
                </p>
                <div class="title"><strong>财富分析</strong></div>
                <p>
                    {{ order.data_extend.return.info.tywh.hyhw }}
                </p>
            </div>
        </div>
        <input type="image" :src="require('@/assets/bazijp/images/result_master_jiedu_04.png')" class="master-jiedu">
        <div class="section">
            <div class="heading">
                <h3>健康分析</h3>
            </div>
            <div class="content">
                <p>
                    <!-- {{ order.data_extend.return.data.rgxx.jkfx }}<br> -->
                    易患疾病: {{ order.data_extend.return.info.wharr.whjk.jb }}<br>
                    易发症状: {{ order.data_extend.return.info.wharr.whjk.zz }}<br>
                    从中医养生上来说，您基本上是[{{ order.data_extend.return.info.wharr.wang }}]型人。<br>
                    养生要点: {{ order.data_extend.return.info.wharr.whjk.yd }}<br>
                    生活起居: {{ order.data_extend.return.info.wharr.whjk.sh }}<br>
                    饮食调养: {{ order.data_extend.return.info.wharr.whjk.ys }}<br>
                    保健膳食: {{ order.data_extend.return.info.wharr.whjk.bj }}
                </p>
                <p>
                    生辰预测健康是通过五行生克旺衰以及相冲破害等进行合理预测，帮助我们在生活中有意识和方向去预防，不能作为疾病确诊的依据和判断，如果有身体不适，还是要尽快就医
                </p>
            </div>
        </div>
        <input type="image" :src="require('@/assets/bazijp/images/result_master_jiedu_05.png')" class="master-jiedu">
        <div class="section" v-if="data.data_extend.sxth">
            <div class="heading">
                <h3>三命通会</h3>
            </div>
            <div class="content">
                <p>
                    {{ order.data_extend.sxth.tf1 }}
                    {{ order.data_extend.sxth.tf2 }}
                </p>
            </div>
            <input type="image" :src="require('@/assets/bazijp/images/result_master_jiedu.png')" class="master-jiedu">
        </div>
        <div class="section">
            <div class="heading">
                <h3>什么是喜用神</h3>
            </div>
            <div class="content">
                <p>
                    喜用神是中国传统八字命运学上的术语，喜用神是喜神与用神的合称，命局有“不及”和“太过”等情况，称之为“病”，而“用神”正是针对不同的“病”所下的“药”。“喜神”则是对“用神”能够起到生扶作用的阴阳五行元素。四柱命局以用神为核心，用神健全有力与否，影响人一生的命。一生补救与否，影响人一生的运。凡用神之力不足或缺失，都会导致人生多劳少成，运势欠佳，对财运事业姻缘都会有相应的影响，四柱若是没有用神，可以从后天风水中为自己补足。<b>你的命盘中五行失衡，会导致个人磁场比较弱，当走到不利于你的大运流年运势时就容易感到心力憔悴，建议找师兄为您调理。</b>
                </p>
            </div>
        </div>
        <input type="image" :src="require('@/assets/bazijp/images/result_master_jiedu.png')" class="master-jiedu">
        <div class="section">
            <div class="heading">
                <h3>未来一年</h3>
            </div>
            <div class="content">
                <div class="month" v-for="(m, index) in data.data_extend.myq_text" :key="index">
                    <div class="month-heading">
                        <strong>{{ m.month }}</strong>
                        <small>{{ m.start_date }}～{{ m.end_date }}</small>
                    </div>
                    <div class="month-content">
                        {{ m.content }}
                    </div>
                    <div class="month-footing">
                        <input type="image" :src="require('@/assets/bazijp/images/result_master_1dui1_btn.png')">
                    </div>
                </div>
            </div>
        </div>
        <div class="section" v-if="footMasterJieduImage">
            <input type="image" :src="require('@/assets/images/' + footMasterJieduImage)" class="master-jiedu">
        </div>
        <div class="section">
            <div class="heading">
                <h3>八字命局总结</h3>
            </div>
            <div class="content">
                <p>
                    一、你的八字其实无论是感情还是财运上的运势都不算差，但是因为命局中五行失衡且喜用神不够强旺，导致泄财泄运比较严重，婚姻感情比较容易发生重大转变情况，建议进行补旺喜用五行的调理，对你的格局来说比较关键。
                </p>
                <p>
                    二、你的命局中格局不差，只是气运欠佳，要长时间积蓄方有所成。一方面要不断增强自己的体质和实力，这样才有足够的精力去赚取更多钱财。另一方面则还得走大运和流年，大运好的年份可以获得不错的收成，求财机遇才会到来，运势差的年份最好退避三舍，否则付出再多也收不回来。财运提升建议可以补旺财库。这样即便是在差的大运流年也是有机会得到很大改善的，在好的流年更是锦上添花。
                </p>
                <p>
                    三、虽然感情比较难得偿所愿，总体还算顺利，但还感情婚姻姻缘方面，是容易受到受生债的影响这个受生债对你的事业运也会有影响，建议化解。
                </p>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    name: 'BzjpResult',
    data() {
        return {
            masterImage: process.env.VUE_APP_JIEGUO_MASTER_IMAGE_PATH,
            footMasterJieduImage: process.env.VUE_APP_JIEGUO_FOOT_MASTER_JIEDU_IMAGE_PATH,
            data: {}
        }
    },
    mounted: function () {
        this.fetchData()
    },
    methods: {
        getFetchWeixin() {
            return this.data.fetch_weixin;
        },
        handleSubmit() {
            window.location.href = this.getFetchWeixin()
            return false
        },
        getBaziHtml(bazi, shishen) {
            let html = bazi + '/';
            let shishenVals = shishen.split("\n");
            for (let i in shishenVals) {
                if (i > 0) {
                    html += '<br>'
                }
                html += "<span class='shishen'>";
                if (i > 0) {
                    html += '　 '
                }
                html += shishenVals[i].replaceAll(/\[|\]/g, '') + "</span>";
            }
            return html;
        },
        getXgfxHtml(content) {
            let items = content.split("\n");
            let html = "<ul>";
            for (let item of items) {
                html += "<li>" + item.replace(/(\d+)\./g, '<span class="number">$1</span> ') + "</li>";
            }
            html += "</ul>";
            return html;
        },
        fetchData() {
            this.$api.post('order/result', { oid: this.$route.query.oid }).then(res => {
                if (res.data.success) {
                    this.data = res.data.data
                } else {
                    console.log(res.data.message)
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/bazijp/sass/result.scss';
</style>