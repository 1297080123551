<template>
    <div class="loading">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
    </div>
</template>

<script>
export default {
    name: 'Loading'
}
</script>

<style scoped lang="scss">
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    display: none;
    /* 使加载器可见 */
}
.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #333;
  margin: 0 10px;
  position: relative;
  animation: ripple 1.2s infinite ease-in-out;
}

.dot:nth-child(2) {
  animation-delay: 0.4s; /* 延迟动画开始 */
}

.dot:nth-child(3) {
  animation-delay: 0.8s; /* 进一步延迟 */
}

@keyframes ripple {
  0% {
    transform: scale(0.6);
    opacity: 0.5;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.6);
    opacity: 0.5;
  }
}
</style>