<template>
    <div class="about">
        <div class="about__header">
            <div class="close" @click="$router.go(-1)"></div>
            <h2>关于我们</h2>
        </div>
        <div class="about__content">
            <p v-html="formatIntro()"></p>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'About',
    computed: {
        ...mapState(['webInfo'])
    },
    methods: {
        formatIntro() {
            return this.webInfo.intro.replace(/\n/g, '<br>')
        }
    }
}
</script>

<style lang="scss" scoped>
.about {
    &__content {
        padding: 1rem .8rem;
        font-size: 0.8rem;
    }

    &__header {
        position: relative;

        .close {
            position: fixed;
            left: .8rem;
            top: 0.8rem;
            width: 1.8rem;
            height: 1.8rem;
            background: rgba($color: #000000, $alpha: 0.1);
            border-radius: 1rem;
            align-items: center;
            justify-content: center;

            &::before {
                content: '';
                position: absolute;
                left: 0.66rem;
                top: 0.6rem;
                width: 0.6rem;
                height: 0.6rem;
                border-left: 0.1rem solid #888;
                border-bottom: 0.1rem solid #888;
                transform: rotate(45deg);
            }
        }

        >h2 {
            text-align: center;
            height: 2.5rem;
            line-height: 2.5rem;
        }
    }
}
</style>