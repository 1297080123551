<template>
  <component :is="currentComponent"></component>
</template>

<script>
import YinyuancsIndex from './yinyuancs/Index.vue';
import BazijpIndex from './bazijp/Index.vue';
import ZiweiIndex from './ziwei/Index.vue';
import ScxpIndex from './scxp/Index.vue';
import HistoryIndex from './history/Index.vue';
import AboutIndex from './about/Index.vue';

export default {
  name: 'Index',
  data() {
    return {
      currentComponent: null
    };
  },
  mounted() {
    this.loadComponentFromQuery()
  },
  methods: {
    loadComponentFromQuery() {
      const queryParams = this.$route.query
      const componentKey = queryParams.ac
      switch (componentKey) {
        case 'scxp':
          this.currentComponent = ScxpIndex
          break
        case 'ziwei':
          this.currentComponent = ZiweiIndex
          break
        case 'bazijp':
          this.currentComponent = BazijpIndex
          break
        case 'yinyuancs':
          this.currentComponent = YinyuancsIndex
          break
        case 'history':
          this.currentComponent = HistoryIndex
          break
        case 'about':
          this.currentComponent = AboutIndex
          break
        default:
          this.currentComponent = null
      }
    },
  },
  watch: {
    '$route'() {
      this.loadComponentFromQuery()
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/app.scss';
</style>