<template>
	<div class="history-index">

	</div>
</template>

<script>
export default {
	name: 'HistoryIndex',
	components: {
	},
	data() {
		return {
		}
	},
	created() {
	},
	mounted() {
	},
	beforeDestroy() {
	},
	methods: {
	}
}
</script>

<style scoped lang="scss">
@import '@/assets/history/sass/index.scss';
</style>